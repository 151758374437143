import React from 'react'
import Layout from '../components/layout'
import H2 from '../theming/H2'
import { useAgency } from '../hooks/use-agency'
import { usePageContext } from '../hooks/use-page-context'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { CollapsiblePanel } from '../components/collapsible-panel'
import { AgencyAddress } from '../components/agency-address'

export default (props) => {
  const { agency, settings } = useAgency()
  const locale = usePageContext().locale ?? agency.locale

  return (
    <Layout {...props} title="page.terms">
      <div className="c-row c-row--alpha">
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp4">
              <H2 className="section-title--big">
                <FormattedMessage id="page.terms" />
              </H2>
            </div>
          </div>
        </div>
      </div>
      <div className="c-row c-row--alpha">
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp4">
              <article className="c-rich-text">
                <FormattedHTMLMessage
                  id="page.terms-body"
                  values={{
                    name: agency.name,
                    website: settings.agency_website_hostname,
                    privacyLink: `/${locale}/privacy`,
                  }}
                />

                <CollapsiblePanel
                  title={<FormattedMessage id="page.terms-section-1-header" />}
                >
                  <p>
                    <FormattedHTMLMessage id="page.terms-section-1-body" />
                  </p>
                </CollapsiblePanel>

                <CollapsiblePanel
                  title={<FormattedMessage id="page.terms-section-2-header" />}
                >
                  <p>
                    <FormattedHTMLMessage id="page.terms-section-2-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={<FormattedMessage id="page.terms-section-3-header" />}
                >
                  <p>
                    <FormattedHTMLMessage id="page.terms-section-3-body" />
                  </p>
                </CollapsiblePanel>

                <CollapsiblePanel
                  title={<FormattedMessage id="page.terms-section-4-header" />}
                >
                  <p>
                    <FormattedHTMLMessage id="page.terms-section-4-body" />
                  </p>
                </CollapsiblePanel>
                <CollapsiblePanel
                  title={<FormattedMessage id="page.terms-section-5-header" />}
                >
                  <p>
                    <FormattedHTMLMessage id="page.terms-section-5-body" />
                  </p>
                </CollapsiblePanel>

                <CollapsiblePanel
                  title={
                    <FormattedMessage id="terms.contact-information.title" />
                  }
                >
                  <p>
                    <FormattedMessage
                      id="terms.contact-information.body"
                      values={{
                        email: (
                          <a href={`mailto:${settings.email}`}>
                            {settings.email}
                          </a>
                        ),
                        address: <AgencyAddress />,
                      }}
                    />
                  </p>
                  <p className="text--right">
                    <AgencyAddress /> <br />
                    {settings.registration_number}
                  </p>
                </CollapsiblePanel>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
